
import './Header.css';
import image from '../assets/images/headerImg.png';


function Header() {
    return (
       
        <header>
            <div class="container">
                <nav class="navbar navbar-dark bg-transparenet">
                    <a class="navbar-brand" href="#">
                        <img src={image} alt="Image" />
                    </a>
                    <div class="row">
                        <div class="col-md-6 navbar-text">+918121921954</div>
                        <div class="col-md-6 navbar-text">experienceoursafar@gmail.com</div>
                        
                    </div>

                   
                </nav>
            </div>
        </header>
       
    );
}

export default Header;