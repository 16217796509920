


function Footer() {
    return (
        <footer>
        <p>Stay connected</p>
            <nav class="footer-social-links">
                <a href="https://www.instagram.com/our.safar/" class="social-link"><i class="mdi mdi-instagram"></i></a>
                <a href="https://www.facebook.com/oursafars/" class="social-link"><i class="mdi mdi-facebook-box"></i></a>
                <a href="https://www.linkedin.com/company/oursafar/" class="social-link"><i class="mdi mdi-linkedin-box"></i></a>
                <a href="https://www.youtube.com/@OurSafars" class="social-link"><i class="mdi mdi-youtube"></i></a>
                
                </nav>
        </footer>
    );
}

export default Footer;