import Header from './Header/Header';
import ComingSoon from './ComingSoon/ComingSoon';
import Footer from './Footer/Footer';
import './App.css';


function App() {
  return (
      <div className="App wrapper">
          <Header />
          <ComingSoon />
          <Footer />
    </div>
  );
}

export default App;
