import './ComingSoon.css';
import image from '../assets/images/coming-soon.png';

function ComingSoon() {
    return (

        <main class="my-auto">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 section-left">
                        <h1 class="page-title">We're coming soon</h1>
                    </div>

                    <div class="col-md-6 align-items-center">
                        <img src={image} alt="coming soon" class="img-fluid" />
                    </div>
                   
                </div>
               
            </div>
        </main>

    );
}

export default ComingSoon;